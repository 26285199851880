// For staging - livedoc-dev.transmissionmedia.ca

const rootUri = 'https://livedoc-dev.transmissionmedia.ca/';

export default {
	ApiRootUri: rootUri,
	ApiUri: `${rootUri}api/`,
	JobTypesUri: `${rootUri}api/jobmanagement/`,
	MetaApiUri: `${rootUri}api/repository/idx/`,
	RepositoryUri: `${rootUri}api/repository/`,
	ScriptSvcUri: `${rootUri}api/scriptsvc/`,
    UserManagementUri: `${rootUri}api/usermanagement/`,
	SignalR: {
		Root: `${rootUri}signalr`,
		Hub: `${rootUri}signalr/clients`
	},
	UseJwt: true,
    oidcClientSettings: {
        'authorization_endpoint': `${rootUri}connect/authorize`,
		'client_id': 'livedoc4reactui',
		'scope': 'openid profile livedocApi',
		'redirect_prefix': 'ui/'
	}
};
